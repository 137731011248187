import { schoolService } from "../../api/schools.service";
const state = () => ({
  items: [],
  venues:[],
});

// getters
const getters = {
  schools: state => {
    return state ? state.items : [];
  },
};
// actions
const actions = {
  setVenues({ state,commit, dispatch }, venues) {
    commit("setVenues", venues);
    return schoolService.get(state.venues).then((response) => {
      commit("setItems", response);      
      // reset selected venue
      const venueId = response && response.length > 0 ? response[0].id : 0;
      const schoolName = response && response.length > 0 ? response[0].name : '';

      dispatch('visitors/setFilters',{ selectedVenueId: venueId },{ root: true });
      dispatch('visitors/setSelectedSchoolName', schoolName.trim(), { root: true })
    });    
  },
};
// mutations
const mutations = {
  setVenues(state , venues) {
    state.venues = typeof venues === String || typeof venues === "string" 
                      ? [venues]
                      : venues;
    
  },
  setItems(state , items) {
    state.items = items
  },  
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
