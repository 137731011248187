<template>
  <div 
    class="unauthorized--container"
    :class="{'mobile': $vuetify.breakpoint.smAndDown}"
  >
    <div class="unauthorized--content d-flex justify-center">
        <slot name="blockContent">
          <v-card class="py-9 unauthorized--card">
            <div class="d-flex flex-column align-center">
              <div class="d-flex justify-center pb-12">
                <div class="d-flex align-center justify-center icon-circle">
                  <v-icon
                    class="icon"
                    size="120px"
                  >
                    mdi-alert-circle-outline
                  </v-icon>
                </div>
              </div>
              <div class="mb-7">
                <div class="unauthorized--not-compatible-text">
                  Access Denied
                </div>
              </div>
              <div class="mb-3">
                <div
                  class="unauthorized--cta-text"
                >
                  You do not have access to this application. If you require access, please contact your school principal.
                </div>
                
                <v-container class="mt-3">
                  <AdsButton
                    :tertiary="true"
                    buttonText="Log out"
                    @click="logOut"
                  ></AdsButton>
                </v-container>
              </div>
            </div>
          </v-card>
        </slot>
      </div>
  </div>
</template>

<script>
import { AdsButton } from "@nswdoe/doe-ui-core";

export default {
  name: 'unauthorized',
  components: {
    AdsButton
  },
  data: (vm) => ({
    visitorName: vm.$attrs.visitorName,
    checkinTime: vm.$attrs.checkinTime,
    govIcon: null,
  }),
  mounted() {
    this.$nextTick(() => {
      // hide header and banner, fix format
      const header = document.getElementById('appHeader');
      const banner = document.getElementById('appBanner');
      const mobileSchoolMenu = document.getElementById('schoolSelectMobile');
      const mainContainer = document.getElementsByClassName('v-main');

      if (header) {
        header.style.display = 'none';
      }

      if (banner) {
        banner.style.display = 'none';
      }

      if (mobileSchoolMenu) {
        mobileSchoolMenu.style.display = 'none';
      }

      if (mainContainer[0]) {
        mainContainer[0].style.padding = '0px';
      }
    });
  },
  methods: {
    logOut() {
      this.$OAuth.logout();
    },
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
  margin-top: 30px;
}

.unauthorized--container.mobile {
    padding-top: 56px;
  }
  .unauthorized--container {
    width: 100%;
    height: 100%;
    background-color: #E5E5E5 !important;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 10;
    padding-top: 100px;
    padding-left: 24px;
    padding-right: 24px;
  }
  .unauthorized--content {
    min-width: 250px;
  }
  .unauthorized--card {
    max-width: 640px;
    padding: 34px 100px;
    text-align: center;
  }
  .icon-circle {
    width: 185px;
    height: 185px;
    border-radius: 50%;
    background-color: #C8DCF0;
  }
  .icon {
    color: #041E42;
  }
  .unauthorized--not-compatible-text {
    font-size: 24px;
    font-weight: bold;
    line-height: 2rem;
  }
</style>