import axios from "axios";
// import Vue from 'vue';
// import {localStorageService} from './local.storage.service'
const baseURL = process.env.VUE_APP_API_URL;

const baseService = axios.create({  
  baseURL: baseURL,  
});

baseService.interceptors.request.use((config) => {
  // Vue.prototype.$OAuth.buildHttpHeader({
  //   "Accept": "application/json",
  //   "Content-Type": "application/json; charset=UTF-8"
  // })
  //const state = localStorageService.getCurrentUser();
  //if(state && state.currentUser){
    // config.url += config.url.indexOf("?")> -1 ? '&' : '?';
    // config.url +=  `access_token=${state.currentUser.cirrus_token}`;
  //}
  return config;
  },
);

export default baseService;